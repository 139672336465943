import React from "react"
import { BoxWrapper, BuildingAddress } from "components"
import { Footer } from "./style"

const Contact = ({
  boxWrapperProps,
  buildingAddressProps,
}: IContactContainerProps) => {
  return (
    <BoxWrapper {...boxWrapperProps}>
      <Footer>
        <BuildingAddress {...buildingAddressProps} />
      </Footer>
    </BoxWrapper>
  )
}

export default Contact

import React from "react"
import { BoxWrapper, RelatedCaseStudies } from "components"
import { RelatedCaseStudiesWrapper } from "./style"

const CaseStudies = ({
  boxWrapperProps,
  relatedCaseStudiesProps,
}: ICaseStudiesInteriorContainerProps) => {
  return (
    <BoxWrapper {...boxWrapperProps}>
      <RelatedCaseStudiesWrapper>
        <RelatedCaseStudies {...relatedCaseStudiesProps} />
      </RelatedCaseStudiesWrapper>
    </BoxWrapper>
  )
}

export default CaseStudies

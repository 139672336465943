import React from "react"
import {
  Carousel,
  BannerWithPicture,
  Testimonial,
  BannerWithLeftPicture,
  Articles,
} from "components"

const Home = ({
  carouselProps,
  bannerWithPictureProps,
  testimonialsProps,
  bannerWithLeftPictureProps,
  articlesProps,
}: IHomeContainerProps) => {
  return (
    <>
      <Carousel {...carouselProps} />
      <BannerWithPicture {...bannerWithPictureProps} />
      <Testimonial {...testimonialsProps} />
      <BannerWithLeftPicture {...bannerWithLeftPictureProps} />
      <Articles {...articlesProps} />
    </>
  )
}

export default Home

import React from "react"
import { BoxWrapper, RelatedArticles } from "components"
import { RelatedArticlesWrapper } from "./style"

const NewsInterior = ({
  boxWrapperProps,
  relatedArticlesProps,
}: INewsInteriorContainerProps) => {
  return (
    <BoxWrapper {...boxWrapperProps}>
      <RelatedArticlesWrapper>
        <RelatedArticles {...relatedArticlesProps} />
      </RelatedArticlesWrapper>
    </BoxWrapper>
  )
}

export default NewsInterior
